.App {
  font-family: sans-serif;
  text-align: center;
}

.dropdown-menu {
  max-height: 150px;
  width: 100%;
  overflow-y: scroll;
}

.drop-down-list li {
  text-align: left;
  display: block;
  padding-bottom: 10px;
  font-size: 0.9rem !important;
  font-weight: 400;
  border-bottom: 1px solid #d2d2d2;
  margin-top: 10px;
  cursor: pointer;
}
.drop-down-list li:last-child {
  border-bottom: none;
}
.drop-down-list {
  position: absolute;
  background: #fff;
  z-index: 2;
  border: 1px solid #d5dae2;
  width: 100%;
  top: 75px;
  padding: 10px !important;
  box-shadow: 0 10px 15px #969696;
  height: 200px;
  overflow-y: auto;
}
.drop-box-product {
  background: white;
  position: absolute;
  top: 40px;
  width: 100%;
  height: 178px;
  z-index: 2;
  box-shadow: 0 5px 10px #ccc;
  border-radius: 5px;

}
.list-product {
  height:100%;
  overflow-y: auto;
  padding: 5px !important;
}
.list-product li {
  text-align: left;
  border-bottom: 1px solid #ccc;
  padding: 5px;
}