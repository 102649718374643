@media (max-width: 600px) {
  .navbar-brand-img,
  .navbar-brand > img {
    max-height: 3.5rem;
  }
  .for-mobile-space {
    margin-left: 10px !important;
  }
  .btn-responsive {
    width: auto !important;
  }
  .margin-left-zero{margin-left: 0px !important;}
}
